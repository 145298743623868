import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../components/layout';
export const _frontmatter = {
  "title": "Blocks"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Blocks`}</h1>
    <p>{`The Blocks UI editor comes with a collection of built-in components that you can
use in the editor. They're referred to as Blocks.`}</p>
    <h2>{`Headers`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/blocks/headers/basic/"
        }}>{`Basic`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/blocks/headers/logo/"
        }}>{`Logo`}</a></li>
    </ul>
    <h2>{`Taglines`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/blocks/taglines/bold/"
        }}>{`Bold`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/blocks/taglines/content/"
        }}>{`Content`}</a></li>
    </ul>
    <h2>{`Quotes`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/blocks/quotes/basic/"
        }}>{`Basic`}</a></li>
    </ul>
    <h2>{`Footers`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/blocks/footers/basic/"
        }}>{`Basic`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      